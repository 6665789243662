<template>
  <div>
    <label>
      {{ $t('address.label.customer') }}
    </label>
    <v-select
      id="select-address"
      v-model="address.customer"
      :options.sync="customers"
      :reduce="name => name.id"
      :get-option-label="getOptionLabel"
      @input="onChanged"
    >
      <span
        slot="selected-option"
        slot-scope="option"
      >
        <span v-if="option.id">
          {{ option.name }}, {{ option.city }}
        </span>
      </span>
      <template #option="{ name, city }">
        <span>{{ name }}, {{ city }}</span>
      </template>

    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    address: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    customers() {
      return this.$store.getters['customer/getCustomers']
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('customer/fetchCustomers', { limit: 1000 })
    },
    onChanged(val) {
      this.$emit('input', val)
    },
    getOptionLabel(suggestion) {
      return `${suggestion.name} ${suggestion.city}, ${suggestion.addressLine1}`
    },
  },
}
</script>
