<template>
  <div id="edit-address">
    <b-row class="match-height">

      <b-col
        md="6"
        cols="12"
      >
        <b-card>

          <rc-overlay
            :show="loading"
            no-fade
            variant="white"
          >
            <validation-observer ref="editAddressValidation">
              <form
                id="editAddressForm"
                @submit.prevent
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="pb-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('address.label.name')"
                      :rules="address.searchable ? 'required|max:50' : ''"
                    >
                      <label for="name">
                        {{ $t('address.label.name') }}
                      </label>
                      <b-form-input
                        id="name"
                        v-model="address.name"
                        :disabled="!address.searchable"
                        type="text"
                        minlength="3"
                        maxlength="50"
                        :placeholder="$t('address.label.name')"
                        autocomplete="chrome-off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    class="pb-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('address.label.alias')"
                      :rules="address.searchable ? 'required|max:50' : ''"
                    >
                      <label for="alias">
                        {{ $t('address.label.alias') }}
                      </label>
                      <b-form-input
                        id="alias"
                        v-model="address.alias"
                        :disabled="!address.searchable"
                        type="text"
                        minlength="3"
                        maxlength="50"
                        :placeholder="$t('address.label.alias')"
                        autocomplete="chrome-off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    class="pb-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('address.label.street_number')"
                      rules="required|min:3|max:50"
                    >
                      <label for="address">
                        {{ $t('address.label.street_number') }}
                      </label>
                      <b-form-input
                        id="address"
                        v-model="address.address"
                        type="text"
                        minlength="3"
                        maxlength="50"
                        :placeholder="$t('address.label.street_number')"
                        autocomplete="chrome-off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    class="pb-1"
                  >
                    <b-row>
                      <b-col
                        cols="4"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('address.label.zip')"
                          rules="required|min:3|max:10"
                        >
                          <label for="zip">
                            {{ $t('address.label.zip') }}
                          </label>
                          <b-form-input
                            id="zip"
                            v-model="address.zip"
                            type="text"
                            minlength="3"
                            maxlength="10"
                            :placeholder="$t('address.label.zip')"
                            autocomplete="chrome-off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>

                      <b-col
                        cols="8"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('address.label.city')"
                          rules="required|min:3|max:50"
                        >
                          <label for="city">
                            {{ $t('address.label.city') }}
                          </label>
                          <b-form-input
                            id="city"
                            v-model="address.city"
                            type="text"
                            minlength="3"
                            maxlength="50"
                            :placeholder="$t('address.label.city')"
                            autocomplete="chrome-off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    class="pb-1"
                  >

                    <b-form-checkbox
                      v-model="address.private"
                      @change="handlePrivateChange"
                    >
                      {{ $t('order.label.address.is-private') }}
                    </b-form-checkbox>

                  </b-col>

                  <b-col
                    cols="12"
                    class="pb-1"
                  >

                    <b-form-checkbox
                      v-model="address.searchable"
                      :disabled="address.private"
                      @change="handleSearchableChange"
                    >
                      {{ $t('order.label.address.searchable') }}
                    </b-form-checkbox>

                  </b-col>

                </b-row>

                <address-customer-selector
                  v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])"
                  :address="address"
                  :submit="submitForm"
                  @input="onCustomerChange"
                />
              </form>

            </validation-observer>
          </rc-overlay>

          <template #footer>

            <div class="text-right">
              <b-button
                class="mr-1"
                variant="outline-warning"
                @click="$router.back()"
              >
                {{ $t('shared.button.cancel') }}
              </b-button>

              <b-button
                variant="primary"
                :disabled="loading"
                @click="submitForm"
              >
                {{ $t('shared.button.submit') }}
              </b-button>
            </div>

          </template>

        </b-card>
      </b-col>

      <b-col
        md="6"
        cols="12"
      >
        <b-card
          no-body
          no-title
          no-footer
        >
          <iframe
            v-if="showMap"
            class="border-0 h-100 rounded"
            :src="getMapUrl()"
            allowfullscreen
          />
        </b-card>
      </b-col>

    </b-row>

    <b-row>
      <b-col>
        <b-card :title="$t('address.details.title')">
          <rc-overlay
            :show="loading"
            no-fade
            variant="white"
          >
            <div
              v-for="(addressDetails, addressDetailsKey) in address.addressDetails"
              :key="`addressDetails-${addressDetails.id}-${addressDetailsKey}`"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="pb-1"
                >
                  <b-form-input
                    v-model="addressDetails.value"
                    type="text"
                    :placeholder="addressDetails.label"
                    @blur="saveDetail(addressDetailsKey)"
                  />
                </b-col>
              </b-row>
            </div>
          </rc-overlay>

          <template #footer>

            <div class="text-right">
              <b-button
                variant="primary"
                :disabled="loading"
                @click="addDetail"
              >
                {{ $t('shared.button.add') }}
              </b-button>
            </div>

          </template>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import AddressCustomerSelector from '@/views/addresses/AddressEdit/AddressCustomerSelector.vue'
import {
  BButton, BCard, BCol, BFormCheckbox, BFormInput, BRow,
} from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import guard from '@/guard'

export default {
  components: {
    AddressCustomerSelector,
    BFormInput,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    RcOverlay,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      showMap: false,
      loading: false,
    }
  },
  computed: {
    address: {
      set(address) {
        return this.$store.commit('address/SET_ADDRESS_EDITED', address)
      },
      get() {
        return cloneNested(this.$store.getters['address/getAddressData'])
      },
    },
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
  },
  watch: {
    address: {
      handler() {
        this.showMap = false
        this.showMap = true
      },
      deep: true,
    },
  },
  created() {
    this.initAddressData()
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    initAddressData() {
      this.loading = true
      this.$store.dispatch('address/fetchAddressById', this.$route.params.id)
        .then(() => {
          this.loading = false
          this.showMap = true
        })
        .catch(err => parseRequestError(err))
    },
    submitForm() {
      this.$refs.editAddressValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.loading = true
          const addressData = cloneNested(this.address)

          const payload = {
            id: addressData.id,
            data: addressData,
          }
          this.$store.dispatch('address/updateAddress', payload)
            .then(() => {
              window.toast.notify.success(this.$t('address.notify.edit.success_title'))
            })
            .catch(err => {
              parseRequestError(err)
              this.initAddressData()
            })
            .finally(() => {
              this.loading = false
            })

          return true
        })
    },
    handleSearchableChange() {
      const address = cloneNested(this.address)
      if (!this.address.searchable) {
        address.name = ''
        address.alias = ''
      } else {
        address.private = false
      }
      this.address = address
    },
    handlePrivateChange() {
      const address = cloneNested(this.address)
      if (this.address.private) {
        address.searchable = false
      }
      this.address = address
    },
    onCustomerChange(val) {
      const address = cloneNested(this.address)
      address.customer = val
      this.address = address
    },
    getMapUrl() {
      const queryParams = {
        key: 'AIzaSyB5zbQZqenSwG_xowvr2lQ4_HfU_ZD-CQY',
        q: `${this.address.city} ${this.address.zip}, ${this.address.address}`,
      }

      return `https://www.google.com/maps/embed/v1/place?${Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&')}`
    },
    addDetail() {
      const address = cloneNested(this.address)
      address.addressDetails.push({
        value: '',
      })
      this.address = address
    },
    saveDetail(addressDetailsIndex) {
      const addressDetails = cloneNested(this.address.addressDetails[addressDetailsIndex])

      if (!addressDetails.value) {
        window.toast.notify.danger(this.$t('address.details.notify.empty_value_error'))
        return
      }

      if (!addressDetails.id) {
        this.$store.dispatch('address/postAddressDetail', {
          addressId: this.address.id,
          data: addressDetails,
        })
          .then(res => {
            window.toast.notify.success(this.$t('address.details.notify.add_success_title'))

            addressDetails.id = res.data.id
            const address = cloneNested(this.address)
            address.addressDetails[addressDetailsIndex] = addressDetails
            this.$store.commit('address/SET_ADDRESS_EDITED', address)
          })
          .catch(err => parseRequestError(err))

        return
      }

      this.$store.dispatch('address/patchAddressDetail', {
        addressId: this.address.id,
        addressDetailId: addressDetails.id,
        data: addressDetails,
      })
        .then(() => {
          window.toast.notify.success(this.$t('address.details.notify.edit_success_title'))
        })
        .catch(err => parseRequestError(err))
    },
  },
}
</script>
